<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <b-form-group label="Nome">
          <b-input v-model="obj.nome" />
        </b-form-group>
      </div>
      <div class="col-12 col-md-6">
        <b-form-group label="Tipo">
          <b-select
            v-model="obj.tipo"
            :options="[
              { value: 1, text: 'Crédito' },
              { value: -1, text: 'Débito' },
            ]"
          />
        </b-form-group>
      </div>
      <div class="col-12 col-md-6">
        <b>Grupo Resumo</b>
        <b-select
          :options="['Loja', 'Ifood', 'Despesas']"
          v-model="obj.grupo_resumo"
        >
        </b-select>
      </div>
      <div class="col-12 col-md-6 pt-4">
        <b-checkbox v-model="obj.contabilizado" :value="1" :unchecked-value="0">
          <b>Contabilizado</b>
        </b-checkbox>
      </div>
    </div>
    <div class="col-12 text-right">
      <hr />
      <b-btn variant="success" class="mr-1" @click="salvar"
        ><i class="fa fa-save" /> Salvar</b-btn
      >
      <b-btn variant="secondary" @click="$emit('close')"
        ><i class="fa fa-ban" /> Cancelar</b-btn
      >
    </div>
  </div>
</template>

<script>
import validador from "../../../utils/validador";
import HistoricoLib from "../../../libs/BancoTipoHistoricoLib";
export default {
  components: {},
  props: {
    editID: {
      type: Number,
      default: null,
    },
  },
  mounted() {
    this.carregar();
  },
  data() {
    return {
      obj: {
        nome: "",
        tipo: "",
        contabilizado: 0,

        id: null,
      },
    };
  },
  watch: {
    editID: function () {
      this.obj = {
        nome: "",
        tipo: "",
        contabilizado: 0,

        id: null,
      };
      this.carregar();
    },
  },
  computed: {},
  methods: {
    async salvar() {
      let errs = [];
      if (validador.string.isNullOrEmpty(this.obj.nome)) {
        errs.push("Informe o Nome");
      }
      if (validador.string.isNullOrEmpty(this.obj.tipo)) {
        errs.push("Selecione o tipo");
      }

      if (errs.length > 0) {
        this.$swal({
          title: "Atenção",
          html: `<div class="text-left">${errs.join("<br/>")}</div>`,
          icon: "error",
        });
      } else {
        let result = await HistoricoLib.storeTipoHistorico(this.obj);
        this.$swal(
          result.success ? "Sucesso" : "Erro",
          result.success
            ? "Historico cadastrado com sucesso!"
            : "Erro ao cadastrar",
          result.success ? "success" : "error"
        );

        if (result.success) {
          this.$emit("close");
        }
      }
    },
    async carregar() {
      console.log("id", this.$props.editID);
      if (this.$props.editID) {
        let r = await HistoricoLib.getTipoHistorico({ id: this.editID });
        if (r.success && r.data.length >= 1) {
          let { nome, tipo, banco, agencia, conta, id, grupo_resumo, contabilizado } =
            r.data[0];
          this.obj = { nome, tipo, banco, agencia, conta, id, grupo_resumo, contabilizado };
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
