<template>
  <div>
    <barra-crud
      :botaoNovo="true"
      :onNovo="
        () => {
          this.openHistorico(null);
        }
      "
    />

    <b-table
      :items="lista"
      :fields="[
        { key: 'id', label: 'Código' },
        { key: 'nome', label: 'Nome' },
        { key: 'tp', label: 'Tipo' },
        { key: 'contabilizado', label: 'Contabilizado' },
        { key: 'bot', label: '' }
      ]"
    >
      <template #cell(bot)="row">
        <b-btn variant="warning" class="mr-1" @click="openHistorico(row.item.id)"
          ><i class="fa fa-edit" /> Editar</b-btn
        >
        <b-btn variant="danger" @click="excluir(row.item.id)"
          ><i class="fa fa-trash" /> Excluir</b-btn
        >
      </template>
      <template #cell(contabilizado)="row">
        <b-badge variant="success" v-if="row.item.contabilizado==1">Sim</b-badge>
        <b-badge variant="danger" v-else>Não</b-badge>
      </template>
    </b-table>

    <b-modal
      title="Adicionar/Editar Tipo de Histórico"
      no-enforce-focus
      hide-footer
      id="modal-Historico"
    >
      <Historicos-form @close="fecharHistorico" :editID="editID" />
    </b-modal>
  </div>
</template>

<script>
import BarraCrud from "../../../components/common/BarraCrud.vue";
import HistoricosForm from "../../../components/Financeiro/TipoHistorico/TipoHistoricoForm.vue";
import HistoricoLib from "../../../libs/BancoTipoHistoricoLib";
export default {
  components: {
    BarraCrud,
    HistoricosForm
  },
  props: {},
  mounted() {
    this.carregar();
  },
  data() {
    return {
      lista: [],
      loading: false,
      editID: null
    };
  },
  watch: {},
  computed: {},
  methods: {
    openHistorico(id) {
      this.editID = id;
      console.log("edit id-->", id);
      this.$bvModal.show("modal-Historico");
    },
    fecharHistorico() {
      this.$bvModal.hide("modal-Historico");
      this.editObj = null;
      this.carregar();
    },
    excluir(id) {
      this.$swal({
        title: "Confirmação",
        text: "Tem certeza que deseja excluir este Tipo de Historico?",
        icon: "question",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim. Excluir!"
      }).then(async resp => {
        if (resp.isConfirmed) {
          let res = await HistoricoLib.delTipoHistorico(id);
          this.$swal(
            res.success ? "Sucesso" : "Erro",
            res.success
              ? "Historico excluida com sucesso!"
              : "Erro ao excluir",
            res.success ? "success" : "error"
          );

          if(res.success){
              this.carregar()
          }
        }
      });
    },
    async carregar() {
      this.loading = true;
      this.lista = ((await HistoricoLib.getTipoHistorico(null)).data).map(it=>
      {
          return {...it, tp: it.tipo==1?'Crédito':'Débito'}
      });
      console.log("lista", this.lista);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>