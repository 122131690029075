var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('barra-crud',{attrs:{"botaoNovo":true,"onNovo":() => {
        this.openHistorico(null);
      }}}),_c('b-table',{attrs:{"items":_vm.lista,"fields":[
      { key: 'id', label: 'Código' },
      { key: 'nome', label: 'Nome' },
      { key: 'tp', label: 'Tipo' },
      { key: 'contabilizado', label: 'Contabilizado' },
      { key: 'bot', label: '' }
    ]},scopedSlots:_vm._u([{key:"cell(bot)",fn:function(row){return [_c('b-btn',{staticClass:"mr-1",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.openHistorico(row.item.id)}}},[_c('i',{staticClass:"fa fa-edit"}),_vm._v(" Editar")]),_c('b-btn',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.excluir(row.item.id)}}},[_c('i',{staticClass:"fa fa-trash"}),_vm._v(" Excluir")])]}},{key:"cell(contabilizado)",fn:function(row){return [(row.item.contabilizado==1)?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("Sim")]):_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("Não")])]}}])}),_c('b-modal',{attrs:{"title":"Adicionar/Editar Tipo de Histórico","no-enforce-focus":"","hide-footer":"","id":"modal-Historico"}},[_c('Historicos-form',{attrs:{"editID":_vm.editID},on:{"close":_vm.fecharHistorico}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }